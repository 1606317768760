import {permissions} from "@/enums/permissions.enum";

export default [
    {
        title: 'dashboard',
        icon: 'home-icon',
        tagVariant: 'light-warning',
        route: 'dashboard',
        permissions: [],
    },
    {
        title: 'orders',
        icon: 'orders-icon',
        tagVariant: 'light-warning',
        route: 'orders',
        permissions: [permissions.readOrders]
    },
    {
        title: 'chat',
        icon: 'nav-chat-icon',
        tagVariant: 'light-warning',
        route: 'chat',
        permissions: [permissions.chat]
    },
    {
        title: 'ads',
        icon: 'nav-ads-icon',
        tagVariant: 'light-warning',
        route: 'ads',
        permissions: [permissions.readAds]
    },
    {
        title: 'talqah_business.title',
        icon: 'nav-ads-icon',
        tagVariant: 'light-warning',
        // route: 'talqah_business',
        permissions: [permissions.readOrders, permissions.addCompanies],
        children: [
            {
                title: 'talqah_business.orders.title',
                tagVariant: 'light-warning',
                route: 'talqah-business-orders',
                permissions: [permissions.readOrders],
            },
            {
                title: 'talqah_business.providers.title',
                tagVariant: 'light-warning',
                route: 'talqah-business-providers',
                permissions: [permissions.addCompanies],
            },
            {
                title: 'talqah_business.branches.title',
                tagVariant: 'light-warning',
                route: 'talqah-business-branches-create',
                permissions: [permissions.addCompanies],
            },
        ]
    },
    {
        title: 'users.title',
        icon: 'nav-users-icon',

        tagVariant: 'light-warning',
        route: 'users',

        permissions: [permissions.readClientsInfo, permissions.readCompaniesInfo, permissions.readDriversInfo],

        children: [
            {
                title: 'users.clients',
                icon: 'nav-other-icon',
                tagVariant: 'light-warning',
                route: 'users-clients',
                permissions: [permissions.readClientsInfo]
            },
            {
                title: 'users.vendors',
                icon: 'nav-other-icon',
                tagVariant: 'light-warning',
                route: 'users-vendors',
                permissions: [permissions.readCompaniesInfo, permissions.readCompaniesFinancials, permissions.readCompaniesProducts, permissions.readCompaniesCategories, permissions.readCompaniesOrders, permissions.readCompaniesReviews],
            },
            {
                title: 'users.drivers',
                icon: 'nav-other-icon',
                tagVariant: 'light-warning',
                route: 'users-drivers',
                permissions: [permissions.readDriversInfo, permissions.readDriversFinancials, permissions.readDriversOrders, permissions.readDriversReviews],
            },
        ]
    },


    {

        title: 'financial.title',
        icon: 'money-icon',
        tagVariant: 'light-warning',
        route: 'financial-all',
        permissions: [permissions.readFinancials],

    },
    {

        title: 'financial.office.title',
        icon: 'money-icon',
        tagVariant: 'light-warning',
        // route: 'financial-office',
        permissions: [permissions.readFinancials],

        children: [
            {
                title: 'financial.office.penalties_and_tenders',
                tagVariant: 'light-warning',
                route: 'penalties',
                permissions: [permissions.readFinancials],
            },
            {
                title: 'financial.office.captains_collections',
                tagVariant: 'light-warning',
                route: 'captains-collection',
                permissions: [permissions.readFinancials],
            },
            {
                title: 'financial.office.vendors_payments',
                tagVariant: 'light-warning',
                route: 'vendors-payments',
                permissions: [permissions.readFinancials],
            },
        ]

    },
    // {
    //
    //     title: 'financial.reports.special.title',
    //     icon: 'money-icon',
    //     tagVariant: 'light-warning',
    //     route: 'financial-transactions',
    //     permissions: [permissions.readFinancials],
    //
    //
    //     children: [
    //
    //         {
    //             title: 'financial.reports.special.drivers',
    //             tagVariant: 'light-warning',
    //             route: 'drivers-reports',
    //             permissions: [permissions.readFinancials],
    //         },
    //         {
    //             title: 'financial.reports.special.vendors',
    //             tagVariant: 'light-warning',
    //             route: 'vendors-reports',
    //             permissions: [permissions.readFinancials],
    //         },
    //
    //     ]
    //
    // },
    {

        title: 'reports',
        icon: 'nav-reports-icon',
        tagVariant: 'light-warning',
        route: 'reports',
        permissions: [permissions.readFinancials],//todo:: change permission

    },

    {
        title: 'discounts',
        icon: 'coupons-icon',
        tagVariant: 'light-warning',


        permissions: [permissions.readDiscounts],
        children: [
            {
                title: 'discounts-platform',
                icon: 'nav-other-icon',
                tagVariant: 'light-warning',
                route: 'discounts',
                permissions: [permissions.readDiscounts],
            },
            {
                title: 'discounts-vendors',
                icon: 'nav-other-icon',
                tagVariant: 'light-warning',
                route: 'discounts-vendors',
                permissions: [permissions.readDiscounts],
            }
        ]
    },


    {
        title: 'reviews',
        icon: 'star-icon',
        tagVariant: 'light-warning',
        route: 'reviews',
        permissions: [permissions.readReviews],
    },
    {
        title: 'settings',
        icon: 'setting-icon',
        tagVariant: 'light-warning',
        route: 'settings',
        permissions: [],
    },
];
